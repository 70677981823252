import {Message, MessageClass, RawMessage} from "./message_type";

function get_api_path() {
	const url = new URL(window.location.toString());
	url.hostname = url.hostname.replace("chat.", "chat-backend.");
	url.pathname = "/api";
	url.hash = "";
	if (url.hostname.startsWith("local")) {
		url.protocol = "https";
		url.host = url.hostname + ":4443";
	}
	return url;
}

const API_BASE_PATH = get_api_path();

async function update_chat(): Promise<Array<Message>> {
	const res = await fetch(API_BASE_PATH + "/chat/global");
	console.debug("Update:", res);
	if (res.ok) {
		const response = await res.json() as Array<RawMessage>;
		return response.map(MessageClass.from);
	}
	throw new Error("Update chat: " + res.statusText);
}

function send_message(input: HTMLInputElement) {
	const text = input.value;
	input.value = "";
	fetch(API_BASE_PATH + "/chat/global", {
		method: "POST",
		body: text,
	}).then((response) =>
		console.log("Send message response:", response.status, response.statusText)
	);
}

export {send_message, update_chat, API_BASE_PATH};