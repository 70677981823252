class MessageClass implements Message {
	content: string;
	date: Date;
	sender: Sender;

	constructor(raw: RawMessage) {
		this.content = raw.content;
		this.date = new Date(raw.timestamp);
		this.sender = new Sender(raw.sender);
	}

	static from(raw: RawMessage) {
		return new MessageClass(raw);
	}

	get id(): string {
		return this.date.toISOString();
	}
}

class Sender {
	name: string;

	ip: string;

	constructor(raw: RawSender) {
		if (raw.NamedAnon) {
			this.name = raw.NamedAnon.name;
			this.ip = raw.NamedAnon.ip;
		} else {
			this.name = "Anon"
			this.ip = raw.Anon;
		}
	}
}

interface Message {
	get content(): string,

	get date(): Date,

	get sender(): Sender,

	get id(): string,
}

type CUnion<T extends Record<PropertyKey, unknown>> = { [K in keyof T]: { [_ in K]: T[K] } & { [_ in Exclude<keyof T, K>]?: undefined } }[keyof T];
type RawSender = CUnion<{
	"Anon": string,
	"NamedAnon": { "name": string, "ip": string },
}>;
type RawMessage = {
	sender: RawSender,
	timestamp: string,
	content: string
};

export type {RawMessage, Message};
export {MessageClass};