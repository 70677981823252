import type {Message, RawMessage} from "./message_type";
import {MessageClass} from "./message_type";
import {API_BASE_PATH} from "./api";

class WebsocketChat {
	private readonly callback: (message: Message) => void;
	private socket: WebSocket | undefined;

	private reconnect = true;
	private updater?: () => Promise<void>;

	constructor(callback: (message: Message) => void) {
		this.callback = callback;
	}

	connect() {
		const url = new URL(API_BASE_PATH);
		url.protocol = url.protocol.replace("http", "ws");
		url.pathname = "/api/ws/global";
		const socket = this.socket = new WebSocket(url);

		// Connection opened
		socket.addEventListener('open', () => {
			console.info("Websocket connected");
			if (this.updater)
				this.updater().then(() => console.info("Chat update to sync with websocket"));
		});

		// Listen for messages
		socket.addEventListener('message', (event) => {
			const parsed = JSON.parse(event.data) as RawMessage;
			const message = new MessageClass(parsed);
			this.callback(message);
			console.debug('Message from server ', message);
		});

		socket.addEventListener("close", (event) => {
			console.info("Websocket closed");
			if (!this.reconnect) return;
			console.debug("WebSocket should reconnect, opening new connection", event);
			window.setTimeout(() => this.connect(), 1000);
		});
	}

	close() {
		console.info("Closing socket");
		this.reconnect = false;
		this.socket?.close();
	}

	onConnect(updater: () => Promise<void>) {
		this.updater = updater;
	}
}

export {WebsocketChat};