import {batch, createSignal, For, onCleanup, onMount} from "solid-js";

//Manages messages, and update the chat window
import type {Message} from "./message_type";
import {WebsocketChat} from "./ws";
import {send_message, update_chat} from "./api";

function MessageElement(props: { message: Message }) {
	// eslint-disable-next-line solid/reactivity
	const message = props.message;
	const sender = message.sender;
	return (
		<div class="message">
			<header>
				<bdi>{sender.name}</bdi>
				<div class="sender-ip">[{sender.ip}]</div>
				at
				<time>{message.date.toLocaleString()}</time>
			</header>
			<div class="message-content">{message.content}</div>
		</div>);
}

const Chat = () => {
	const [messages, setMessages] = createSignal(new Map<string, Message>(), {equals: false});

	function push(msg: Message) {
		const id = msg.id;
		if (messages().has(id)) return;
		setMessages(map => map.set(id, msg));
		console.debug("New message:", msg);
	}

	async function updater() {
		const messages = await update_chat();
		batch(() => messages.forEach((msg) => push(msg)));
	}

	const interval = setInterval(updater, 30000);
	onCleanup(() => clearInterval(interval));
	onMount(() => {
		updater().then(() => console.log("initial chat update"));
		const ws = new WebsocketChat(push);
		ws.onConnect(updater);
		ws.connect();
		onCleanup(() => ws.close());
	})

	function ordered() {
		return Array.from(messages().entries())
			.sort(([, a], [, b]) =>
				a.date.valueOf() - b.date.valueOf());
	}

	let message_input: HTMLInputElement;
	return (
		<>
			<div class="chat">
				<For each={ordered()}>
					{([, message]) => <MessageElement message={message}/>}
				</For>
			</div>
			<form class="message-form" onSubmit={(e: Event) => {
				send_message(message_input);
				e.preventDefault()
			}}>
				<input ref={ref => message_input = ref} type="text" class="message-input" maxlength={512}
					   placeholder="Type your message"/>
				<button id="send" type="submit">Send</button>
			</form>
		</>
	);
}

export default Chat;